<template>
    <div>
      <keep-alive >
          <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  created(){
  
      //传递code，获取unionId，获取信息，缓存
      // console.log("aapppp",window.location.href,window.location.href.split('#')[0])
      let code = this.getRequest().code
      let specialId = this.getRequest().state
      this.toLogin(code)
      console.log("获取code",this.getRequest().code,specialId)

      if(specialId){
        this.$router.push('/login?specialId='+specialId)
      }

      // let data = this.getRequest().state
      // this.toLogin(code)
      // console.log("获取code",this.getRequest().code,data)

      // if(data){
      //   if(data.specialId){
      //     this.$router.push('/login?specialId='+data.specialId)
      //   }
      //   if(data.takePigThemeId){
      //     this.$router.push('/orderTakeList?takePigThemeId='+data.takePigThemeId)
      //   }
      // }

  },
  methods:{
    //获取参数
    getRequest() {
      // let a = 'https://testgeyou.guguzhineng.com/auction-h5/?code=071Lbx000xAhzN1JGN100P72PF2Lbx0Q&state=#/home'
      // let url = a.split('#')[0].split('?')[1]; //获取url中"?"符后的字串  
      let url = window.location.href.split('#')[0].split('?')[1]; //获取url中"?"符后的字串  
      let theRequest = new Object();
      let strs = url.split("&");
      for(let i = 0; i < strs.length; i ++) {
        theRequest[strs[i].split("=")[0]]=decodeURI(strs[i].split("=")[1]);
      }
      return theRequest;
    },

    //静默登录
    toLogin(code){
        this.$axios.get('/auction/user/login/'+code).
        then((res) => {
            console.log('登录成功',res)
            let userInfo = res.data.data
            let auction_token = res.data.dataObj
            console.log("获取个人信息参数",userInfo,auction_token)

            this.$ls.set('auction_token',auction_token);
            this.$ls.set('userInfo',userInfo);

            this.$store.dispatch('user/setToken',auction_token)
            this.$store.dispatch('user/setUserInfo',userInfo)

        })
        // .catch((rej) => {
        //     console.log('失败',rej);
        //     this.$tips.fail(rej.msg||'系统异常')
        // });
    },

  }
}
</script>

