import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueStorage from './plugins/storage'
import VueAxios from './plugins/axios'
import DateTimeFormat from './plugins/datetime'
import WebConfig from './web.config'
import VueClipboard from 'vue-clipboard2'


Vue.use(VueClipboard)

import Vant from 'vant';
import { Toast , Dialog } from 'vant';


import 'amfe-flexible'
import 'amfe-flexible/index.js'

import 'vant/lib/index.css';

Vue.use(Vant);
Vue.prototype.$tips = Toast;
Vue.prototype.$dialog = Dialog;


/**
 * web配置文件
 */
 Vue.webConfig = WebConfig;
 Vue.prototype.$webConfig = WebConfig;
// import Qs from 'qs';

// Vue.prototype.$qs = Qs;

Vue.config.productionTip = false;



/**
 * 基础样式表
 */
// import './assets/css/base.css'
import './assets/css/home.css'
import './assets/css/iconFont.css'


/**
 * Storage
 */
Vue.use(VueStorage);

/**
 * axios
 */
Vue.use(VueAxios, { router: router, Toast: Toast });


/**
 * 时间扩展方法
 */
Vue.use(DateTimeFormat);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')