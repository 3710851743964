import axios from 'axios'

import wx from 'weixin-js-sdk'

axios.defaults.timeout = 10000;
export default {
    install: (Vue, options) => {
        process.env.NODE_ENV == "development" ? axios.defaults.baseURL = "/api" : axios.defaults.baseURL = Vue.webConfig.apiHost;
        /*请求过滤*/
        axios.interceptors.request.use(
            config => {
                config.withCredentials = true;
                let auction_token = Vue.ls.get('auction_token')
                config.headers['token']=auction_token;
                // config.headers['token']='5cb6f008250c4e4ea8e90eabfd86d58a';
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        /*响应过滤*/
        axios.interceptors.response.use(
            response => {
                const res = response.data
                if (res.code !== 200) {
                    if ( res.code === 503 ) {
                        // Vue.ls.clear(); //清除所
                        console.log("登录失效，这里清楚缓存")
                        options.Toast.fail('请重新登录')
                        // options.router.push({ path: "/login" });
                    }
                    return Promise.reject(res || 'Error')
                } else {
                  return response
                }
              
            },
            error => {
                console.log(error);
                return Promise.reject(error);
            }
        );
        // //微信支付配置封装
        axios.getWxConfig=function(url,type){
            // console.log("aaaaaaaaaa",url,type)
            return new Promise((resolve, reject) => {
                  axios.get(url)
                  .then(function(res) {
                    //  console.log("获取参数成功",res) 
                    const data = res.data.data //返回wx.config需要的参数
                    wx.config({
                      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                      appId: data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
                      timestamp: data.timeStamp, // 必填，生成签名的时间戳
                      nonceStr: data.nonceStr, // 必填，生成签名的随机串
                      signature: data.paySign, // 必填，签名，见附录1
                      jsApiList: [type] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                    })
                    wx.ready(function() {
                      resolve(data)
                    })
                    wx.error(function(res) {
                      console.log(res)
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                    })
                  })
                  .catch(function(error) {
                    reject(error)
                  })
                })
        };
        // //微信配置封装
        axios.getWxConfigAll=function(url,type){
          // console.log("aaaaaaaaaa",url,type)
          return new Promise((resolve, reject) => {
                axios.get(url)
                .then(function(res) {
                  // console.log("获取参数成功",res) 
                  const data = res.data.data //返回wx.config需要的参数
                  // console.log('---------------1---------------',data,data.appId,data.timestamp,data.nonceStr,data.signature)
                  wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
                    timestamp: data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: data.nonceStr, // 必填，生成签名的随机串
                    signature: data.signature, // 必填，签名，见附录1
                    jsApiList: [...type] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                  })
                  wx.ready(function() {
                    resolve(data)
                  })
                  wx.error(function(res) {
                    console.log(res)
                      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                  })
                })
                .catch(function(error) {
                  reject(error)
                })
              })
      }
        // 添加全局方法
        Vue.axios = axios;
        // 添加实例方法
        Vue.prototype.$axios = axios;
    },
     
}
