import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    routes: [

        {
            path: '*',
            name: '404',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/NotFound.vue"),
            meta: {
                title: '页面找不到',
                keepAlive:true
            }
        },
        {
            path: '/login',
            name: 'logon',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/Logon.vue"),
            meta: {
                title: '数字鸽个人信息',
                keepAlive:true

            }
        },
        
        {
            path: '/',
            name: 'index',
            redirect:'/home',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/Index.vue"),
            meta: {
                title: '数字鸽拍卖',
                keepAlive:true

            },
            children:[
                {
                    path: '/home',
                    name: 'home',
                    component: () =>
                        import ( /* webpackChunkName: "home" */ "./views/Home.vue"),
                    meta: {
                        title: '数字鸽拍卖首页',
                        keepAlive:true

                    },
                },
                {
                    path: '/live',
                    name: 'home',
                    component: () =>
                        import ( /* webpackChunkName: "home" */ "./views/Live.vue"),
                    meta: {
                        title: '数字鸽拍卖直播',
                        keepAlive:true

                    },
                },
                {
                    path: '/user',
                    name: 'user',
                    component: () =>
                        import ( /* webpackChunkName: "home" */ "./views/User.vue"),
                    meta: {
                        title: '数字鸽个人中心',
                        keepAlive:true
                    }
                },
            ]
        },
       
        {
            path: '/sessionList',
            name: 'sessionList',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/SessionList.vue"),
            meta: {
                title: '数字鸽拍卖场次',
            },
        },
        {
            path: '/sessionDetail',
            name: 'sessionDetail',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/SessionDetail.vue"),
            meta: {
                title: '数字鸽拍卖专题详情',
            },
        },
        {
            path: '/pigeonList',
            name: 'pigeonList',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/PigeonList.vue"),
            meta: {
                title: '数字鸽拍卖单场',
            },
        },
        {
            path: '/aucDetail',
            name: 'aucDetail',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/AucDetail.vue"),
            meta: {
                title: '数字鸽拍卖详情',
            },
        },
        {
            path: '/living',
            name: 'living',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/Living.vue"),
            meta: {
                title: '数字鸽正在直播',
            },
        },
        {
            path: '/livingSoon',
            name: 'livingSoon',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/LivingSoon.vue"),
            meta: {
                title: '数字鸽即将开始',
            },
        },
        {
            path: '/bidList',
            name: 'bidList',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/BidList.vue"),
            meta: {
                title: '数字鸽出价详情',
            },
        },
        {
            path: '/search',
            name: 'search',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/Search.vue"),
            meta: {
                title: '数字鸽拍卖搜索',
                keepAlive:true
            },
        },
        {
            path: '/address',
            name: 'address',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/Address.vue"),
            meta: {
                title: '收货地址管理',
            },
        },
       
        {
            path: '/editAddress',
            name: 'editAddress',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/EditAddress.vue"),
            meta: {
                title: '编辑收货地址',
            },
        },
        {
            path: '/orderList',
            name: 'orderList',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/orders/OrderList.vue"),
            meta: {
                title: '我的订单',
            },
        },
        // {
        //     path: '/orderTakeList',
        //     name: 'orderTakeList',
        //     component: () =>
        //         import ( /* webpackChunkName: "home" */ "./views/orders/OrderTakeList.vue"),
        //     meta: {
        //         title: '现场领取订单',
        //     },
        // },
        {
            path: '/orderDetail',
            name: 'orderDetail',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/orders/OrderDetail.vue"),
            meta: {
                title: '订单详情',
            },
        },
      
        {
            path: '/orderRefund',
            name: 'orderRefund',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/orders/OrderRefund.vue"),
            meta: {
                title: '申请售后',
            },
        },
        {
            path: '/orderRefundList',
            name: 'orderRefundList',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/orders/OrderRefundList.vue"),
            meta: {
                title: '售后订单',
            },
        },
        {
            path: '/bondList',
            name: 'bondList',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/BondList.vue"),
            meta: {
                title: '我的保证金',
            },
        },
        {
            path: '/recharge',
            name: 'recharge',
            component: () =>
                import ( /* webpackChunkName: "home" */ "./views/auctions/Recharge.vue"),
            meta: {
                title: '充值保证金',
            },
        },
      
       

    ]
})

/*
   路由守卫
*/

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next()
    // let auction_token = Vue.ls.get('auction_token')
    // if (to.path == '/login' || to.path == '/register') {
    //     next()
    // } else {
    //     if (auction_token == '' || auction_token == null) {
    //         next('/login');
    //     } else {
    //         next()
    //     }
    // }
})


export default router