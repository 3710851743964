let auction_token = JSON.parse(localStorage.getItem('auction_token'))
let userInfo = JSON.parse(localStorage.getItem('userInfo'))
const state = {
  auction_token: auction_token?auction_token.value:'',
  userInfo:userInfo?userInfo.value:{},
}

const mutations = {
  SET_TOKEN: (state, auction_token) => {
    state.auction_token = auction_token
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  
}

const actions = {
  setToken({ commit },data){
    commit('SET_TOKEN', data)
  },
  setUserInfo({ commit },data){
    commit('SET_USERINFO', data)

  }
  // user login
  // login({ commit }, userInfo) {
  //   const { username, password } = userInfo
  //   return new Promise((resolve, reject) => {
  //     login({ username: username.trim(), password: password }).then(response => {
  //       const { data } = response
  //       commit('SET_TOKEN', data.token)
  //       setToken(data.token)
  //       resolve()
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },
  // get user info


  // user logout
  // logout({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     logout(state.token).then(() => {
  //       commit('SET_TOKEN', '')
  //       commit('SET_ROLES', [])
  //       removeToken()
  //       resetRouter()
  //       resolve()
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // // remove token
  // resetToken({ commit }) {
  //   return new Promise(resolve => {
  //     commit('SET_TOKEN', '')
  //     commit('SET_ROLES', [])
  //     removeToken()
  //     resolve()
  //   })
  // },

  // // dynamically modify permissions
  // changeRoles({ commit, dispatch }, role) {
  //   return new Promise(async resolve => {
  //     const token = role + '-token'
  //     commit('SET_TOKEN', token)
  //     setToken(token)
  //     const { roles } = await dispatch('getInfo')
  //     resetRouter()
  //     // generate accessible routes map based on roles
  //     const accessRoutes = await dispatch('information/generateRoutes', roles, { root: true })

  //     // dynamically add accessible routes
  //     router.addRoutes(accessRoutes)

  //     // reset visited views and cached views
  //     dispatch('tagsView/delAllViews', null, { root: true })

  //     resolve()
  //   })
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
